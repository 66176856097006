import React from "react"
import { Container } from "reactstrap"

import SEO from "../components/seo"

import "./privacy.scss"

const PrivacyPolicy = () => (
  <>
    <SEO title="Privacy Policy" />
    <Container>
      <div className="PrivacyPolicy">
        <h1>Privacy Policy</h1>

        <p>Last Updated: 14 Mar 2022</p>

        <p>
          Jadoh Learning Co., Ltd. (“Jadoh,” “we,” and “us”) respects the
          privacy of its users (“you”) and has developed this Privacy Policy to
          demonstrate its commitment to protecting your privacy. This Privacy
          Policy describes the information we collect, how that information may
          be used, with whom it may be shared, and your choices about such uses
          and disclosures. We encourage you to read this Privacy Policy
          carefully when using our application or services or transacting
          business with us. By using our website, application or other online
          services (our “Service”), you are accepting the practices described in
          this Privacy Policy.
        </p>
        <p>
          If you have any questions about our privacy practices, please refer to
          the end of this Privacy Policy for information on how to contact us.
        </p>

        <h2>Information we collect about you</h2>

        <p>
          <b>In General.</b> We may collect Personal Information, including
          Sensitive Data, and other information. “Personal Information” means
          individually identifiable information that would allow us to determine
          the actual identity of, and contact, a specific living person.
          Sensitive Data includes information, comments or content (e.g.
          photographs, video, profile, lifestyle) that you optionally provide
          that may reveal your ethnic origin, nationality, religion and/or
          sexual orientation. By providing Sensitive Data to us, you consent to
          the collection, use and disclosure of Sensitive Data as permitted by
          applicable privacy laws. We may also collect your geolocation
          information with your consent. We may collect this information through
          a website, mobile application, or other online services. By using the
          Service, you are authorizing us to gather, parse and retain data
          related to the provision of the Service. When you provide personal
          information through our Service, the information may be sent to
          servers located in the United States and countries around the world.
        </p>

        <p>
          <b>Information you provide.</b> In order to register as a user with
          Jadoh, you will be asked to sign in using your Facebook login. If you
          do so, you authorize us to access certain Facebook account
          information, such as your public Facebook profile (consistent with
          your privacy settings in Facebook), your email address, interests,
          likes, gender, birthday, education history, current city, photos,
          personal description, friend list, and information about and photos of
          your Facebook friends who might be common Facebook friends with other
          Jadoh users. You will also be asked to allow Jadoh to collect your
          location information from your device when you download or use the
          Service. In addition, we may collect and store any personal
          information you provide while using our Service or in some other
          manner. This may include identifying information, such as your name,
          address, email address and telephone number, and, if you transact
          business with us, financial information. You may also provide us
          photos, a personal description and information about your gender and
          preferences for recommendations, such as search distance, age range
          and gender. If you chat with other Jadoh users, you provide us the
          content of your chats, and if you contact us with a customer service
          or other inquiry, you provide us with the content of that
          communication.
        </p>

        <p>
          <b>Information you provide.</b> In order to register as a user with
          Jadoh, you will be asked to sign in using your Facebook login. If you
          do so, you authorize us to access certain Facebook account
          information, such as your public Facebook profile (consistent with
          your privacy settings in Facebook), your email address, interests,
          likes, gender, birthday, education history, current city, photos,
          personal description, friend list, and information about and photos of
          your Facebook friends who might be common Facebook friends with other
          Jadoh users. You will also be asked to allow Jadoh to collect your
          location information from your device when you download or use the
          Service. In addition, we may collect and store any personal
          information you provide while using our Service or in some other
          manner. This may include identifying information, such as your name,
          address, email address and telephone number, and, if you transact
          business with us, financial information. You may also provide us
          photos, a personal description and information about your gender and
          preferences for recommendations, such as search distance, age range
          and gender. If you chat with other Jadoh users, you provide us the
          content of your chats, and if you contact us with a customer service
          or other inquiry, you provide us with the content of that
          communication.
        </p>

        <p>
          <b>Use of technologies to collect information.</b> We use various
          technologies to collect information from your device and about your
          activities on our Service.
        </p>

        <p>
          <b>Information collected automatically.</b> We automatically collect
          information from your browser or device when you visit our Service.
          This information could include your IP address, device ID and type,
          your browser type and language, the operating system used by your
          device, access times, your mobile device’s geographic location while
          our application is actively running, and the referring website
          address.
        </p>

        <p>
          <b>Cookies and use of cookie and similar data.</b> When you visit our
          Service, we may assign your device one or more cookies or other
          technologies that facilitate personalization to facilitate access to
          our Service and to personalize your experience. Through the use of a
          cookie, we also may automatically collect information about your
          activity on our Service, such as the pages you visit, the time and
          date of your visits and the links you click. If we advertise, we (or
          third parties) may use certain data collected on our Service to show
          you Jadoh advertisements on other sites or applications.
        </p>

        <p>
          <b>Pixel tags.</b> We embed pixel tags (also called web beacons or
          clear GIFs) on web pages, ads, and emails. These tiny, invisible
          graphics are used to access cookies and track user activities (such as
          how many times a page is viewed). We use pixel tags to measure the
          popularity of our features and services. Ad companies also use pixel
          tags to measure the number of ads displayed and their performance
          (such as how many people clicked on an ad).
        </p>

        <p>
          <b>Mobile device IDs.</b> If you're using our app, we use mobile
          device IDs (the unique identifier assigned to a device by the
          manufacturer), or Advertising IDs (for iOS 6 and later), instead of
          cookies, to recognize you. We do this to store your preferences and
          track your use of our app. Unlike cookies, device IDs cannot be
          deleted, but Advertising IDs can be reset in “Settings” on your
          iPhone. Ad companies also use device IDs or Advertising IDs to track
          your use of the app, track the number of ads displayed, measure ad
          performance and display ads that are more relevant to you. Analytics
          companies use device IDs to track information about app usage.
        </p>

        <h2>How we use the information we collect</h2>

        <p>
          <b>In General.</b> We may use information that we collect about you
          to:
        </p>
        <ul>
          <li>
            deliver and improve our products and services, and manage our
            business;
          </li>
          <li>manage your account and provide you with customer support;</li>
          <li>
            perform research and analysis about your use of, or interest in, our
            or others’ products, services, or content;
          </li>
          <li>
            communicate with you by email, postal mail, telephone and/or mobile
            devices about products or services that may be of interest to you
            either from us or other third parties;
          </li>
          <li>
            develop, display, and track content and advertising tailored to your
            interests on our Service and other sites, including providing our
            advertisements to you when you visit other sites;
          </li>
          <li>website or mobile application analytics;</li>
          <li>
            verify your eligibility and deliver prizes in connection with
            contests and sweepstakes;
          </li>
          <li>enforce or exercise any rights in our Terms of Use; and</li>
          <li>
            perform functions or services as otherwise described to you at the
            time of collection.
          </li>
        </ul>

        <h2>With whom we share your information</h2>

        <p>
          Information Shared with Other Users.When you register as a user of
          Jadoh, your Jadoh profile will be viewable by other users of the
          Service. Other users (and in the case of any sharing features
          available on Jadoh, the individuals or apps with whom a Jadoh user may
          choose to share you with) will be able to view information you have
          provided to us directly or through Facebook, such as your Facebook
          photos, any additional photos you upload, your first name, your age,
          approximate number of miles away, your personal description, and
          information you have in common with the person viewing your profile,
          such as common Facebook friends and likes.
        </p>

        <p>
          <b>Personal information.</b> We do not share your personal information
          with others except as indicated in this Privacy Policy or when we
          inform you and give you an opportunity to opt out of having your
          personal information shared.
        </p>

        <p>
          <b>Other Situations.</b> We may disclose your information, including
          personal information:
        </p>
        <ul>
          <li>
            In response to a subpoena or similar investigative demand, a court
            order, or a request for cooperation from a law enforcement or other
            government agency; to establish or exercise our legal rights; to
            defend against legal claims; or as otherwise required by law. In
            such cases, we may raise or waive any legal objection or right
            available to us.
          </li>
          <li>
            When we believe disclosure is appropriate in connection with efforts
            to investigate, prevent, or take other action regarding illegal
            activity, suspected fraud or other wrongdoing; to protect and defend
            the rights, property or safety of our company, our users, our
            employees, or others; to comply with applicable law or cooperate
            with law enforcement; or to enforce our Terms of Use or other
            agreements or policies.
          </li>
          <li>
            In connection with a substantial corporate transaction, such as the
            sale of our business, a divestiture, merger, consolidation, or asset
            sale, or in the unlikely event of bankruptcy.
          </li>
        </ul>

        <p>
          <b>Aggregated and/or non-personal information.</b> We may use and
          share non-personal information we collect under any of the above
          circumstances. We may also share it with third parties to develop and
          deliver targeted advertising on our Service and on websites or
          applications of third parties, and to analyze and report on
          advertising you see. We may combine non-personal information we
          collect with additional non-personal information collected from other
          sources. We also may share aggregated, non-personal information, or
          personal information in hashed, non-human readable form, with third
          parties, including advisors, advertisers and investors, for the
          purpose of conducting general business analysis, advertising,
          marketing, or other business purposes. For example, we may engage a
          data provider who may collect web log data from you (including IP
          address and information about your browser or operating system), or
          place or recognize a unique cookie on your browser to enable you to
          receive customized ads or content. The cookies may reﬂect
          de-identified demographic or other data linked to data you voluntarily
          have submitted to us (such as your email address), that we may share
          with a data provider solely in hashed, non-human readable form. We may
          also share your geolocation information in de-identified form with
          third parties for the above purposes.
        </p>

        <h2>How you can access, correct and delete your information</h2>

        <p>
          If you have a Jadoh account, you have the ability to review, update
          and delete your personal information within the Service by i) opening
          your account and going to settings in case of review and update, and
          ii) sending a request email to privacy@jadoh.co with the subject:
          “Request for Account Deletion” in case of account deletion. Applicable
          privacy laws may allow you the right to access and/or request the
          correction of errors or omissions in your personal information that is
          in our custody or under our control. Our Privacy Officer will assist
          you with the access request. This includes:
        </p>
        <ul>
          <li>
            Identification of personal information under our custody or control;
            and
          </li>
          <li>
            Information about how personal information under our control may be
            or has been used by us.
          </li>
        </ul>
        <p>
          We will respond to requests within the time allowed by all applicable
          privacy laws and will make every effort to respond as accurately and
          completely as possible. Any corrections made to personal information
          will be promptly sent to any organization to which it was disclosed.
        </p>
        <p>
          In certain exceptional circumstances, we may not be able to provide
          access to certain personal information we hold. For security purposes,
          not all personal information is accessible and amendable by the
          Privacy Officer. If access or corrections cannot be provided, we will
          notify the individual making the request within 30 days, in writing,
          of the reasons for the refusal.
        </p>

        <h2>Data retention</h2>

        <p>
          We keep your information only as long as we need it for legitimate
          business purposes and as permitted by applicable legal requirements.
          If you close your account, we will retain certain data for analytical
          purposes and recordkeeping integrity, as well as to prevent fraud,
          enforce our Terms of Use, take actions we deem necessary to protect
          the integrity of our Service or our users, or take other actions
          otherwise permitted by law. In addition, if certain information has
          already been provided to third parties as described in this Privacy
          Policy, retention of that information will be subject to those third
          parties’ policies.
        </p>

        <h2>Your choices about collection and use of your information</h2>

        <p>
          You can choose not to provide us with certain information, but that
          may result in you being unable to use certain features of our Service
          because such information may be required in order for you to register
          as user; purchase products or services; participate in a contest,
          promotion, survey, or sweepstakes; ask a question; or initiate other
          transactions.
        </p>

        <p>
          You can also control information collected by cookies. You can delete
          or decline cookies by changing your browser settings. Click “help” in
          the toolbar of most browsers for instructions.
        </p>

        <h2>How we protect your personal information</h2>

        <p>
          We take security measures to help safeguard your personal information
          from unauthorized access and disclosure. However, no system can be
          completely secure. Therefore, although we take steps to secure your
          information, we do not promise, and you should not expect, that your
          personal information, chats, or other communications will always
          remain secure. Users should also take care with how they handle and
          disclose their personal information and should avoid sending personal
          information through insecure email. Please refer to the Federal Trade
          Commission's website at
          http://www.ftc.gov/bcp/menus/consumer/data.shtm for information about
          how to protect yourself against identity theft.
        </p>

        <p>
          You agree that we may communicate with you electronically regarding
          security, privacy, and administrative issues, such as security
          breaches. We may post a notice on our Service if a security breach
          occurs. We may also send an email to you at the email address you have
          provided to us. You may have a legal right to receive this notice in
          writing.
        </p>

        <h2>Information you provide about yourself while using our Service</h2>

        <p>
          We provide areas on our Service where you can post information about
          yourself and others and communicate with others. Such postings are
          governed by our Terms of Use. Also, whenever you voluntarily disclose
          personal information on publicly-viewable pages, that information will
          be publicly available and can be collected and used by others. For
          example, if you post your email address, you may receive unsolicited
          messages. We cannot control who reads your posting or what other users
          may do with the information you voluntarily post, so we encourage you
          to exercise discretion and caution with respect to your personal
          information.
        </p>

        <h2>No Rights of Third Parties</h2>

        <p>
          This Privacy Policy does not create rights enforceable by third
          parties or require disclosure of any personal information relating to
          users of the website.
        </p>

        <h2>Changes to this Privacy Policy</h2>

        <p>
          We will occasionally update this Privacy Policy to reflect changes in
          the law, our data collection and use practices, the features of our
          Service, or advances in technology. If we make any material changes to
          this Privacy Policy, we will notify you of the changes by reasonable
          means, which could include notifications through the Services or via
          email. Please review the changes carefully. Your continued use of the
          Services following the posting of changes to this policy will mean you
          consent to and accept those changes. If you do not consent to such
          changes you can delete your account by following the instructions
          under Settings.
        </p>

        <h2>How to contact us</h2>

        <p>
          If you have any questions about this Privacy Policy, please contact us
          by email or postal mail as follows:
        </p>

        <p>privacy@jadoh.co</p>

        <p>
          Privacy Officer
          <br />
          JADOH Learning (Thailand) Co., Ltd.
          <br />
          54/54 Langsuan Lumphini Pathumwan Bangkok Thailand 10330
        </p>
      </div>
    </Container>
  </>
)

export default PrivacyPolicy
